<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Virtual Number Set</div>
    <div class="
          col-lg-6 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        ">
      <button type="button" class="btn custom-view-detail-btn" @click="backtovirtualnumber()">
        Back To Virtual Number
      </button>
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item w-50" role="presentation">
      <button class="nav-link w-100" id="new-setting-tab" data-bs-toggle="tab" data-bs-target="#newvotersetting"
        type="button" role="tab" aria-controls="vehicle-details" aria-selected="false" @click="redirectnewvirtualurl()">
        New Voters Settings
      </button>
    </li>
    <li class="nav-item w-50" role="presentation">
      <button class="nav-link active w-100" id="existing-setting-tab" data-bs-toggle="tab"
        data-bs-target="#existingvotersetting" type="button" role="tab" aria-controls="customer-details"
        aria-selected="true">
        Existing Voters Settings
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade" id="newvotersetting" role="tabpanel" aria-labelledby="new-setting-tab">

    </div>
    <div class="tab-pane fade show active" id="existingvotersetting" role="tabpanel"
      aria-labelledby="existing-setting-tab">
      <div class="custom-tab-outer">
        <div class="search-voter-section-outer" style="height: calc(100vh - 212px);">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Existing Voter Setting<span class="text-danger">*</span></label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="votersetting" name="newvoter-setting" id="newvoter"
                    autocomplete="off" value="0" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="newvoter">As Per New Voter Settings</label>
                  <input type="radio" class="btn-check" v-model="votersetting" name="existing-setting" id="existingvoter"
                    autocomplete="off" value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="existingvoter">As Per Existing Voter
                    Settings</label>
                </div>
                <div class="custom-error" v-if="v$.votersetting.$error">
                  {{ v$.votersetting.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="votersetting == 1">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="custom-form-group">
                <label class="form-label">Define Call Forwarding Tune<span class="text-danger">*</span></label>
                <div class="custom-group-radio-box-btn">
                  <Multiselect v-model="definecallforwardtune" :options="defineholdgreetinglist" label="label"
                    placeholder="Select Define Call Forwarding Tune" class="multiselect-custom text-capitalize"
                    :canClear="false" :closeOnSelect="true" :object="true" @select="
                      handleGreetingType(
                        definecallforwardtune.value
                      )
                      " />
                  <div class="custom-error" v-if="v$.definecallforwardtune.$error">
                    {{ v$.definecallforwardtune.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="custom-form-group">
                <label class="form-label">Welcome Tune<span class="text-danger">*</span></label>
                <Multiselect v-model="definecallwelcometune" :disabled="definecallforwardtune == ''"
                  :options="greetingtypelist" label="label" placeholder="Select Welcome Tune"
                  class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true" :object="true" />
                <div class="custom-error" v-if="v$.definecallwelcometune.$error">
                  {{ v$.definecallwelcometune.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="votersetting == 1">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="custom-form-group">
                <label class="form-label">Call Forwarding Tune<span class="text-danger">*</span></label>
                <div class="custom-group-radio-box-btn">
                  <Multiselect v-model="callforwardtune" :options="callforwardtunelist" label="label"
                    placeholder="Select Call Forwarding Tune" class="multiselect-custom text-capitalize" :canClear="false"
                    :closeOnSelect="true" :object="true" @select="
                      definehandleHoldGreetingType(
                        callforwardtune.value
                      )
                      " />
                  <div class="custom-error" v-if="v$.callforwardtune.$error">
                    {{ v$.callforwardtune.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="custom-form-group">
                <label class="form-label">Hold Tune<span class="text-danger">*</span></label>
                <Multiselect v-model="callholdtune" :disabled="callforwardtune == ''" :options="holdgreetingtypelist"
                  label="label" placeholder="Select Hold Tune" class="multiselect-custom text-capitalize"
                  :canClear="false" :closeOnSelect="true" :object="true" />
                <div class="custom-error" v-if="v$.callholdtune.$error">
                  {{ v$.callholdtune.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="custom-form-group text-center">
                <button type="button" :disabled="showexitsetloader" @click="submitExistSettingBtn()"
                  class="btn modal-bulk-next-btn">
                  <span v-if="!showexitsetloader">Submit</span>
                  <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showexitsetloader">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, requiredIf, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      defineholdgreetinglist: [
        { label: "Default Tune", value: "1" },
        { label: "Custom Tune", value: "2" },
      ],
      callforwardtunelist: [
        { label: "Default Tune", value: "1" },
        { label: "Custom Tune", value: "2" },
      ],
      holdgreetingtypelist: [],
      greetingtypelist: [],
      cn1: null,
      ca1: null,
      votersetting: '',
      definecallforwardtune: "",
      definecallwelcometune: "",
      callforwardtune: "",
      callholdtune: "",
      showexitsetloader: false,
      definecallwelcometunevalue: '',

    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.getExistingSettings({ setting_id: atob(this.$route.params.param2) });
    this.cn1 = atob(this.$route.params.param1);
    this.ca1 = atob(this.$route.params.param2);
  },

  validations() {
    return {
      votersetting: {
        required: helpers.withMessage("Please select setting type", required),
      },
      definecallforwardtune: {
        requiredIf: helpers.withMessage(
          "Please select define call forward tune",
          requiredIf(
            this.votersetting == 1
          )
        ),
      },
      definecallwelcometune: {
        requiredIf: helpers.withMessage(
          "Please select define call welcome tune",
          requiredIf(
            this.votersetting == 1
          )
        ),
      },
      callforwardtune: {
        requiredIf: helpers.withMessage(
          "Please select call forward tune",
          requiredIf(
            this.votersetting == 1
          )
        ),
      },
      callholdtune: {
        requiredIf: helpers.withMessage(
          "Please select call hold tune",
          requiredIf(
            this.votersetting == 1
          )
        ),
      },
    };
  },

  methods: {





    getExistingSettings(e) {
      this.loading = true;
      this.ApiService.getExistingSettings(e).then((data) => {
        if (data.success == true) {
          this.votersetting = data.data.ca12;
          if (data.data.ca13 != null && data.data.ca13 != '') {
            if (data.data.ca13 == 1) {
              this.definecallforwardtune = {
                label: "Default Tune",
                value: data.data.ca13,
              };
            } else if (data.data.ca13 == 2) {
              this.definecallforwardtune = {
                label: "Custom Tune",
                value: data.data.ca13,
              };
            }
          }
          let grettingid = data.data.ca14;
          this.ApiService.getcttunes({type: data.data.ca13}).then((data) => {
            if (data.status == 200) {
              this.greetingtypelist = data.data;
              if (grettingid !== '') {
                let vals = this.greetingtypelist.filter(function (item) {
                  return item.value == grettingid;
                });
                if (vals.length > 0) {
                  this.definecallwelcometune = vals[0];
                }
              }
            }
          });
          if (data.data.ca16 != null && data.data.ca16 != '') {
            if (data.data.ca16 == 1) {
              this.callforwardtune = {
                label: "Default Tune",
                value: data.data.ca16,
              };
            } else if (data.data.ca16 == 2) {
              this.callforwardtune = {
                label: "Custom Tune",
                value: data.data.ca16,
              };
            }
          }
          let holdtuneid = data.data.ca17;
          this.ApiService.getctholdtunes({type: data.data.ca16}).then((data) => {
            if (data.status == 200) {
              this.holdgreetingtypelist = data.data;
              if (holdtuneid !== '') {
                let vals = this.holdgreetingtypelist.filter(function (item) {
                  return item.value == holdtuneid;
                });
                if (vals.length > 0) {
                  this.callholdtune = vals[0];
                }
              }
            }
          });
          this.virtualNumberSettingList = data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.virtualNumberSettingList = null;
        }
      });
    },
    // Define Call Forwarding Tune
    handleGreetingType(e) {
      this.getCtTunes(e);
      this.definecallwelcometune = '';
    },
    getCtTunes(e) {
      this.ApiService.getcttunes({ type: e }).then((data) => {
        if (data.status == 200) {
          this.greetingtypelist = data.data;
        } else {
          this.greetingtypelist = '';
        }
      });
    },
    // Call Forwarding Tune
    definehandleHoldGreetingType(e) {
      this.getHoldCtTunes(e);

      this.callholdtune = '';
    },
    getHoldCtTunes(e) {
      this.ApiService.getctholdtunes({ type: e }).then((data) => {
        if (data.status == 200) {
          this.holdgreetingtypelist = data.data;
        } else {
          this.holdgreetingtypelist = '';
        }

      });
    },
    backtovirtualnumber() {
      this.$router.push("/cloudtelephony/virtualnumbers");
    },

    redirectnewvirtualurl() {
      let routePath = "/cloudtelephony/virtualnumbers/set";
      this.$router.push(`${routePath}/${btoa(this.cn1)}/${btoa(this.ca1)}`);
    },
    submitExistSettingBtn() {
      this.v$.$validate();
      let fields = {};
      fields["ca1"] = this.ca1;
      fields["ca12"] = this.votersetting;
      if (this.votersetting == 1) {
        if (this.definecallforwardtune) {
          fields["ca13"] = this.definecallforwardtune.value;
        }
        if (this.definecallwelcometune) {
          fields["ca14"] = this.definecallwelcometune.value;
          fields["ca15"] = this.definecallwelcometune.tune;
        }
        if (this.callforwardtune) {
          fields["ca16"] = this.callforwardtune.value;
        }
        if (this.callholdtune) {
          fields["ca17"] = this.callholdtune.value;
          fields["ca18"] = this.callholdtune.tune;
        }
      } else {
        fields["ca13"] = '';
        fields["ca14"] = '';
        fields["ca15"] = '';
        fields["ca16"] = '';
        fields["ca17"] = '';
        fields["ca18"] = '';
      }
      if (!this.v$.$error) {
        this.showexitsetloader = true;
        this.ApiService.saveExistingVoterSetting(fields).then((data) => {
          if (data.success == true) {
            this.showexitsetloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.backtovirtualnumber();
          } else {
            this.showexitsetloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.showexitsetloader = false;
      }
    }
  },
};
</script>
<style scoped>
.setting-save-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  padding: 6px 16px;
  font-family: "AcuminPro-Regular";
  min-width: 80px;
}

.setting-save-btn:focus {
  box-shadow: none;
}

.custom-accordion-focus-out-btn {
  user-select: none;
}

.custom-accordion-focus-out-btn:focus {
  box-shadow: none;
}

.divider-custom-list {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #d4e4f3;
}

.divider-custom-list:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.approve-heading {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.approve-save-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  padding: 6px 20px;
  font-family: "AcuminPro-Regular";
  min-width: 100px;
}

.approve-save-btn:focus {
  box-shadow: none;
}

.custom-virtual-set-table thead th {
  border: 1px solid #e4e4e4;
  padding: 1rem 1rem;
  letter-spacing: 0.26px;
  color: #4a5463;
  background: #f2f4fb;
  transition: none;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-virtual-set-table tbody td {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
  border: 1px solid #e4e4e4;
  border-width: 1px;
  padding: 1rem 1rem;
}

.custom-ultima-datatable .label-subheading {
  line-height: 1.5;
}

.virtual-setting-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 19px;
  font-family: "AcuminPro-Regular";
}

.virtual-setting-btn:focus {
  box-shadow: none;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.search-voter-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-voter-section-outer::-webkit-scrollbar {
  display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
</style>